export const environment = {
	production: false,
	env: 'STAGE',
	APP_URL: 'https://stage.gymfans.com/api',
	EVENTURL: 'https://stage.gymfans.com/event',
	AGORA_APP_ID: 'a2be722e9de64d27a0bc9d5a97767cd8',
	ADD_URL: 'https://pubads.g.doubleclick.net/gampad/ads?' +
		'sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&' +
		'impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&' +
		'cust_params=deployment%3Ddevsite%26sample_ct%3Dlinear&correlator=',
	GOOGLE_CLIENT_KEY: "724309048326-c1uif8ro7ua56svh1uqrfqqqq2lmoitp.apps.googleusercontent.com",
	FACEBOOK_CLIENT_KEY: "1649757048696218",
	FACEBOOK_SECRET: "971744b6813c5eda88f1ea05b9695a85",
	WEBSOCKET_URL: "https://stage.gymfans.com",
	APPLE_CLIENT_ID: 'com.gymfans.stage.website',
	APPLE_REDIRECT_URL: 'https://stage.gymfans.com/api/um/v1/user/redirect-apple-signin',
	GOOGLE_PLACE_API: "AIzaSyBSLdJKIeD9Ozb_zxNT_k5Q_bK0r5EmRDI",
	STRIPE_PUB_KEY: "pk_test_51Jdij9B3J6EdDqx9mN0xnEOI3G5aHHgx35lmS0h9MGKfw6heF69LB0k9b1H7Pf7B0t7lf68qLc1ShRp4la1MMM4P00SM0gHIkM",
	firebaseConfig: {
		apiKey: "AIzaSyAjFLqtahYyED_szIzWeVWGjOrkhfmc4X8",
		authDomain: "gymfans-cfa0b.firebaseapp.com",
		projectId: "gymfans-cfa0b",
		storageBucket: "gymfans-cfa0b.appspot.com",
		messagingSenderId: "262603427063",
		appId: "1:262603427063:web:0f60cf1f3c07c200fa97f2",
		measurementId: "G-PHGD5R81DZ"
	},
	AWS_SECRET_ACCESS_KEY: "rj5TkjpNM6a2VAEvIxPor43wsY/wYzvjHrfoSFAI",
	AWS_ACCESS_KEY_ID: "AKIAYGBPLXILTEIYPS7Q",
	AWS_REGION: "eu-west-1",
	AWS_BUCKET: "dev-media-original.gymfans.com",
	RECORDING_BUCKET: "stage-event-recordings",
	DEEP_AR_LICENCE: "992acba215a1414288dcb9048434d9adebec57ae73042f91bf65f1447dc0d8735b888c0085f88d32"
};